import axios from "axios";
import React, { FC, useState, useEffect } from "react";
import InputMask from "react-input-mask";
import Button from "../../components/Button";
import Member from "../../models/Member";
import Agent from "../../models/Agent";
import Payment from "../../models/Payment";
import { states, formatDate, getMaxDate, smartyStreetsApi } from "../../utils";
import { AutoComplete } from "antd";
import cx from "classnames";
import DatePicker from "react-datepicker";
import { z, ZodType } from "zod";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { createPortal } from "react-dom";
import moment from "moment";

type PrimaryInformationProps = {
  agent: Agent;
  member: Member;
  payment: Payment;
  setMember: React.Dispatch<React.SetStateAction<Member>>;
  setPayment: React.Dispatch<React.SetStateAction<Payment>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  zipCodeState: string;
};

type FormData = {
  firstName: string;
  lastName: string;
  birthDate: Date;
  address: string;
  addressTwo?: string;
  gender: string;
  phone: string;
  city: string;
  email?: unknown;
  billingAddress?: unknown;
  billingCity?: unknown;
  billingState?: unknown;
};

const PrimaryInformation: FC<PrimaryInformationProps> = ({
  agent,
  member,
  payment,
  setPayment,
  setStep,
  zipCodeState,
  setMember,
}: PrimaryInformationProps) => {
  const [hasBillingAddress, setHasBillingAddress] = useState(
    payment.paymentCity ? true : false
  );
  const [addressOptions, setAddressOptions] =
    useState<{ value: string; label: string }[]>();

  const [billingAddressOptions, setBillingAddress2Options] =
    useState<{ value: string; label: string }[]>();
  const [city, setCity] = useState(member?.city);
  const [address2, setAddress2] = useState(payment.paymentCity);
  const [billingCity, setBillingCity] = useState(payment.paymentCity);
  const [billingState, setBillingState] = useState(payment.paymentState);
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment("01-01-1977", "MM-DD-YYYY")
  );
  const [showBillingError, setShowBillingError] = useState<boolean>(false);

  const smartyStreets = (
    search: string,
    state: string,
    setter: React.Dispatch<
      React.SetStateAction<
        | {
            value: string;
            label: string;
          }[]
        | undefined
      >
    >
  ) => {
    axios
      .get(`${process.env.REACT_APP_SMARTYSTREETS}`, {
        params: {
          include_only_states: state,
          search: search,
        },
      })
      .then((response) => {
        let newAddressOptions: {
          label: string;
          value: string;
          responseObject: string;
        }[] = [];
        response?.data?.suggestions?.forEach(
          (suggestion: {
            street_line: string;
            secondary: string;
            city: string;
            state: string;
          }) => {
            newAddressOptions.push({
              value: suggestion?.street_line,
              responseObject: JSON.stringify({
                streetLine: suggestion?.street_line,
                secondary: suggestion?.secondary,
                city: suggestion?.city,
                state: suggestion?.state,
              }),
              label:
                suggestion?.street_line +
                ", " +
                (suggestion?.secondary ? suggestion?.secondary + ", " : "") +
                suggestion?.city +
                ", " +
                suggestion?.state,
            });
          }
        );
        setter(newAddressOptions);
      });
  };

  /** React Hook Forms and Zod Implementation  */

  const schema: ZodType<FormData> = z.object({
    firstName: z
      .string()
      .min(2, { message: "First Name must be at least 2 characters" })
      .max(30, { message: "First Name must be less than 30 characters" }),
    lastName: z
      .string()
      .min(2, { message: "Last Name must be at least 2 characters" })
      .max(30, { message: "Last Name must be less than 30 characters" }),
    birthDate: z.date(),
    address: z
      .string({ required_error: "Address must be at least 4 characters" })
      .min(4, { message: "Address must be at least 4 characters" })
      .max(40, { message: "Address must be less than 30 characters" })
      .refine((value) => /[0-9 a-zA-Z]/.test(value), "Invalid Address"),
    addressTwo: z
      .string()
      .min(4)
      .max(40)
      .refine((value) => /[0-9 a-zA-Z]/.test(value), "Invalid Address")
      .optional()
      .or(z.literal("")),
    phone: z
      .string()
      .length(12, { message: "Phone Number must be at 10 digits" })
      .refine(
        (value) =>
          /^(?!000-000-0000|111-111-1111)((\d{3}-\d{3}-\d{4})|(\(\d{3}\) \d{3}-\d{4})|(\d{10}))$/.test(
            value
          ),
        "Invalid Phone Number"
      ),
    gender: z.enum(["M", "F"], {
      errorMap: () => ({ message: "Please select a Gender" }),
    }),
    city: z
      .string()
      .min(2, { message: "City must be at least 2 characters" })
      .max(30, { message: "City must be less than 30 characters" }),
    email: z
      .string()
      .email()
      .or(z.literal(""))
      .refine(
        //@ts-ignore
        (value) => {
          if (agent?.id === 660555) {
            // If agentID is 660555, email is required
            return value !== "";
          }
          // For all other agentIDs, email is not required
          return true;
        }
      ),
    billingAddress: z
      .string()
      .min(4)
      .max(40)
      .refine((value) => /[0-9 a-zA-Z]/.test(value), "Invalid Address")
      .optional()
      .or(z.literal("")),
    billingCity: z.string().min(2).max(20).optional().or(z.literal("")),
    billingState: z.string().min(2).max(15).optional().or(z.literal("")),
  });

  const {
    register,
    handleSubmit,
    getValues, // console.log test
    setValue,
    formState: { errors },
    control,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const submitPrimaryInfo = (data: FormData) => {
    member.dateOfBirth = formatDate(data.birthDate);

    if (hasBillingAddress) {
      if (!data.billingAddress || !data.billingCity || !data.billingState) {
        setShowBillingError(true);
        return;
      }
    }
    setShowBillingError(false);
    setStep((current) => current + 1);
  };

  // TODO: Figure out a better way to do this... browser auto complete
  useEffect(() => {
    setMember({ ...member, city: city });
    setValue("city", city || "");
  }, [city]);

  return (
    <form className="w-10/12" onSubmit={handleSubmit(submitPrimaryInfo)}>
      <div
        className={cx(
          "flex min-h-[70vh] w-full flex-col",
          "xl:pt-17 px-2 py-2"
        )}
      >
        <div className={"page-header-container"}>
          <h1 className={"page-header-text break-words text-center"}>
            Primary Member Information
          </h1>
        </div>
        <div
          className={cx(
            "flex w-full flex-col",
            "rounded-3xl border-4 p-4",
            "border-black bg-white"
          )}
        >
          <div>
            <div
              className={cx(
                "mt-3 grid grid-cols-1 gap-3",
                "lg:grid-cols-4 xl:gap-5"
              )}
            >
              <div className="primary-field-container">
                <label className="form-label">First Name</label>
                <input
                  {...register("firstName")}
                  className={cx("form-input", {
                    "border-red": errors.firstName,
                  })}
                  placeholder="Enter your first name"
                  onChange={(event) => {
                    let value = event.target.value;
                    value = value.replace(/[^A-Za-z\s\-.]/g, "");
                    setMember({ ...member, firstName: value });
                  }}
                  value={member.firstName}
                  data-cy="primaryFirstName"
                />
                {errors.firstName && (
                  <span className="error">{errors.firstName.message}</span>
                )}
              </div>

              <div className="primary-field-container">
                <label className="form-label">Last Name</label>
                <input
                  {...register("lastName")}
                  className={cx("form-input", {
                    "border-red": errors.lastName,
                  })}
                  placeholder="Enter your last name"
                  onChange={(event) => {
                    let value = event.target.value;
                    value = value.replace(/[^A-Za-z\s\-.]/g, "");
                    setMember({ ...member, lastName: value });
                    setValue("lastName", value);
                  }}
                  value={member.lastName}
                  data-cy="primaryLastName"
                />
                {errors.lastName && (
                  <span className="error">{errors.lastName.message}</span>
                )}
              </div>

              <div className="primary-field-container">
                <label className="form-label">Birth Date</label>
                <Controller
                  control={control}
                  name="birthDate"
                  defaultValue={startDate ? startDate.toDate() : undefined}
                  render={({ field }) => (
                    <DatePicker
                      className={cx("form-input w-full", {
                        "border-red": errors.birthDate,
                      })}
                      onChange={(date: Date | null) => {
                        if (date instanceof Date)
                          field.onChange(moment(date).toDate());
                      }}
                      popperContainer={({ children }) =>
                        createPortal(children, document.body)
                      }
                      placeholderText="MM/DD/YYYY"
                      selected={field.value}
                      dateFormat="MM/dd/yyyy"
                      minDate={moment("01/01/1910").toDate()}
                      maxDate={moment(getMaxDate()).toDate()}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  )}
                />
                {errors.birthDate && (
                  <span className="error">{errors.birthDate.message}</span>
                )}
              </div>

              <div className="primary-field-container">
                <label className="form-label">Gender</label>
                <select
                  {...register("gender")}
                  className={cx("form-input", {
                    "border-red": errors.gender,
                  })}
                  onChange={(event) => {
                    setMember({ ...member, gender: event.target.value });
                  }}
                  defaultValue={member.gender}
                  data-cy="primaryGender"
                >
                  <option hidden disabled selected>
                    Select gender
                  </option>
                  <option value={"M"}>Male</option>
                  <option value={"F"}>Female</option>
                </select>
                {errors.gender && (
                  <span className="error">{errors.gender.message}</span>
                )}
              </div>
            </div>

            <div
              className={cx(
                "mt-3 grid grid-cols-1 gap-3",
                "lg:grid-cols-2 xl:gap-5"
              )}
            >
              <div className="primary-field-container mt-3">
                <label className="form-label">Address 1</label>
                <Controller
                  control={control}
                  name="address"
                  defaultValue={member.address1}
                  render={({ field }) => (
                    <AutoComplete
                      className={cx("text-navyBlue")}
                      placeholder={
                        <span className="mt-2 flex">
                          <span className="font-inter text-xl text-[#9da3af]">
                            Enter your address
                          </span>
                        </span>
                      }
                      options={addressOptions}
                      onChange={(event) => {
                        setMember({
                          ...member,
                          address1: event,
                        });
                        field.onChange(event);
                        if (member.state)
                          smartyStreets(event, member.state, setAddressOptions);
                      }}
                      onBlur={(event) => {
                        const target = event.target as HTMLInputElement;
                      }}
                      onSelect={(event, option) => {
                        const newOption = option as {
                          value: string;
                          responseObject: string;
                          label: string;
                        };
                        const parsedOption = JSON.parse(
                          newOption.responseObject
                        );
                        const streetLine = parsedOption.streetLine;
                        const secondary = parsedOption.secondary; // Apartment or street, could be used in address 2 if requested.
                        const cityResponse = parsedOption.city;
                        member.address1 = streetLine;

                        if (secondary) {
                          setAddress2(secondary);
                          setMember({ ...member, address2: secondary });
                          setValue("addressTwo", secondary || "");
                        }
                        if (cityResponse) {
                          setCity(cityResponse);
                          setMember({ ...member, city: cityResponse });
                          setValue("city", cityResponse || "");
                        }
                      }}
                      children={
                        <input
                          className={cx("form-input", {
                            "border-red": errors.address,
                          })}
                          data-cy="address1"
                        />
                      }
                      clearIcon={<></>}
                      defaultValue={member.address1}
                    />
                  )}
                />
                {errors.address && (
                  <span className="error">{errors.address.message}</span>
                )}
              </div>

              <div className="primary-field-container mt-3">
                <label className="form-label">Address 2</label>
                <input
                  {...register("addressTwo")}
                  placeholder={"Enter your address"}
                  key={"address2"}
                  className={cx("form-input", {
                    "border-red": errors.addressTwo,
                  })}
                  value={address2}
                  onChange={(event) => {
                    setMember({
                      ...member,
                      address2: event.target.value,
                    });
                  }}
                />
                {errors.addressTwo && (
                  <span className="error">{errors.addressTwo.message}</span>
                )}
              </div>
            </div>

            <div
              className={cx(
                "mt-3 grid grid-cols-1 gap-3",
                "lg:grid-cols-4 xl:gap-5"
              )}
            >
              <div className="primary-field-container">
                <label className="text-xl font-bold text-navyBlue xl:mb-3">
                  Zip Code
                </label>
                <input
                  className={`form-input`}
                  defaultValue={member.zipCode}
                  disabled
                />
              </div>

              <div className="primary-field-container">
                <label className="form-label">City</label>
                <input
                  {...register("city")}
                  className={cx("form-input", {
                    "border-red": errors.city,
                  })}
                  placeholder="Enter your city"
                  value={city}
                  onChange={(event) => {
                    let value = event.target.value;
                    value = value.replace(/[^A-Z a-z]/gi, "");
                    setMember({ ...member, city: value });
                    setCity(value);
                    setValue("city", city || "");
                  }}
                  data-cy="city"
                />
                {errors.city && (
                  <span className="error">{errors.city.message}</span>
                )}
              </div>

              <div className="primary-field-container">
                <label className="form-label">State</label>
                <input
                  className={`form-input`}
                  defaultValue={member.state}
                  disabled
                />
              </div>
            </div>

            {hasBillingAddress && (
              <div
                className={cx(
                  "mt-3 grid grid-cols-1 gap-3",
                  "xl:grid-cols-4 xl:gap-5"
                )}
              >
                <div className="primary-field-container xl:col-span-2">
                  <label className="form-label">Billing Address</label>
                  <Controller
                    control={control}
                    name="billingAddress"
                    defaultValue={payment.paymentAddress}
                    render={({ field }) => (
                      <AutoComplete
                        {...register("billingAddress")}
                        className={cx("text-navyBlue")}
                        placeholder={
                          <span className="mt-2 flex">
                            <span className="font-inter text-xl text-[#9da3af]">
                              Enter your address
                            </span>
                          </span>
                        }
                        options={billingAddressOptions}
                        onChange={(event) => {
                          setPayment((current) => {
                            current.paymentAddress = event;
                            return current;
                          });
                          field.onChange(event);
                          smartyStreets(
                            event,
                            payment.paymentState ? payment.paymentState : "",
                            setBillingAddress2Options
                          );
                        }}
                        onBlur={(event) => {
                          const target = event.target as HTMLInputElement;
                        }}
                        onSelect={(event, option) => {
                          const newOption = option as {
                            value: string;
                            responseObject: string;
                            label: string;
                          };
                          const parsedResponseObject = JSON.parse(
                            newOption.responseObject
                          );
                          const streetLine = parsedResponseObject.streetLine;
                          const secondary = parsedResponseObject.secondary; // Apartment or street, could be used in address 2 if requested.
                          const cityResponse = parsedResponseObject.city;
                          const stateResponse = parsedResponseObject.state;
                          member.address1 = streetLine;

                          if (cityResponse) {
                            setPayment((current) => {
                              current.paymentCity = cityResponse;
                              return current;
                            });
                            setBillingCity(cityResponse);
                            setValue("billingCity", cityResponse || "");
                          }
                          if (stateResponse) {
                            setPayment((current) => {
                              current.paymentState = stateResponse;
                              return current;
                            });
                            setBillingState(stateResponse);
                            setValue("billingState", stateResponse || "");
                          }
                        }}
                        children={
                          <input
                            className={cx("form-input focus:outline-none", {
                              "border-red":
                                errors.billingAddress ||
                                (showBillingError &&
                                  !getValues().billingAddress),
                            })}
                          />
                        }
                        clearIcon={<></>}
                        defaultValue={payment.paymentAddress}
                      />
                    )}
                  />
                  {errors.billingAddress && (
                    <span className="error">
                      {errors.billingAddress.message}
                    </span>
                  )}
                  {showBillingError && !getValues().billingAddress && (
                    <span className="error">
                      This field is required if Billing Address is checked
                    </span>
                  )}
                </div>

                <div className="primary-field-container">
                  <label className="form-label">Billing City</label>
                  <input
                    {...register("billingCity")}
                    className={cx("form-input", {
                      "border-red":
                        errors.billingCity ||
                        (showBillingError && !getValues().billingCity),
                    })}
                    value={billingCity}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = value.replace(/[^A-Z a-z]/gi, "");
                      setPayment((current) => {
                        current.paymentCity = value;
                        return current;
                      });
                      setBillingCity(value);
                    }}
                    defaultValue={payment.paymentCity}
                  />
                  {errors.billingCity && (
                    <span className="error">{errors.billingCity.message}</span>
                  )}
                  {showBillingError && !getValues().billingCity && (
                    <span className="error">
                      This field is required if Billing Address is checked
                    </span>
                  )}
                </div>

                <div className="primary-field-container">
                  <label className="form-label">Billing State</label>
                  <select
                    {...register("billingState")}
                    className={cx("form-input", {
                      "border-red":
                        errors.billingState ||
                        (showBillingError && !getValues().billingState),
                    })}
                    value={billingState || ""}
                    onChange={(event) => {
                      setPayment({
                        ...payment,
                        paymentState: event.target.value,
                      });
                      setBillingState(event?.target?.value);
                    }}
                    defaultValue={payment.paymentState}
                  >
                    <option value={""} hidden selected>
                      Select State
                    </option>

                    {states.map((state) => {
                      return (
                        <option value={state?.abbreviation}>
                          {state?.name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.billingState && (
                    <span className="error">{errors.billingState.message}</span>
                  )}
                  {showBillingError && !getValues().billingState && (
                    <span className="error">
                      This field is required if Billing Address is checked
                    </span>
                  )}
                </div>
              </div>
            )}

            <div
              className={cx(
                "mt-3 grid grid-cols-1 gap-3",
                "lg:grid-cols-4 xl:gap-5"
              )}
            >
              <div className="primary-field-container">
                <label className="form-label">Phone Number</label>
                <Controller
                  control={control}
                  name="phone"
                  render={({ field }) => (
                    <InputMask
                      mask="999-999-9999"
                      alwaysShowMask={true}
                      className={cx("form-input", {
                        "border-red": errors.phone,
                        "text-[#9ca3af]": member.phone1 === "___-___-____",
                        "text-navyBlue": member.phone1 !== "___-___-_____",
                      })}
                      {...register("phone")}
                      onChange={(event) => {
                        field.onChange(event);
                        setMember({
                          ...member,
                          phone1: event?.target?.value
                            .replace("-", "")
                            .replace("-", "")
                            .replace("-", "")
                            .replace("+", "")
                            .replace("-", "")
                            .replace(" ", ""),
                        });
                      }}
                      value={member.phone1}
                      data-cy="phoneNumber"
                    />
                  )}
                />
                {errors.phone && (
                  <span className="error">{errors.phone.message}</span>
                )}
              </div>

              <div className="primary-field-container">
                <label className="form-label">Email Address</label>
                <input
                  {...register("email")}
                  type="text"
                  className={cx("form-input", {
                    "border-red": errors.email,
                  })}
                  placeholder="Enter your email"
                  onChange={(event) => {
                    setMember({ ...member, email: event.target.value });
                  }}
                  defaultValue={member.email}
                  data-cy="email"
                />
                {errors.email && (
                  <span className="error">Invalid Email Address</span>
                )}
              </div>
            </div>
          </div>

          <div
            className={cx("mt-[22px] flex flex-col items-center lg:flex-row")}
          >
            <div className="order-first flex flex-row items-start justify-self-start">
              <input
                type="radio"
                className={cx(
                  "h-[24px] w-[24px]",
                  "border-solid border-celadonBlue text-celadonBlue accent-celadonBlue checked:bg-lightBlue"
                )}
                checked={hasBillingAddress}
                onClick={() => {
                  setHasBillingAddress(!hasBillingAddress);
                }}
              />
              <label className="ml-2 w-full text-base leading-[24.2px] lg:max-w-[284px] lg:text-[20px]">
                My billing address is different than the address listed above
              </label>
            </div>
            <div
              className={cx(
                "mt-4 h-[49px] w-full text-2xl leading-[38.73px]",
                "lg:mt-0 lg:ml-auto lg:w-[257px] lg:text-[32px]"
              )}
            >
              <Button
                className="submitPrimaryMemberInformation"
                text="Submit"
                submit
                mainPath
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PrimaryInformation;
