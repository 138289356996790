import React, { FC, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useParams, useNavigate } from "react-router-dom";
import ProgressBar from "../../components/ProgressBar";
import ConfirmCart from "../ConfirmCart";
import Coverage from "../Coverage";
import CoverageInformation from "../CoverageInformation";
import DependentInformation from "../DependentInformation";
import PrimaryInformation from "../PrimaryInformation";
import SelectPlan from "../SelectPlan";
import HowManyPeople from "../HowManyPeople";
import Member from "../../models/Member";
import Confirmation from "../Confirmation";
import PaymentInformation from "../Payment";
import Agreements from "../Agreements";
import Agent from "../../models/Agent";
import Plan from "../../models/Plan";
import Dependent from "../../models/Dependent";
import HomePage from "../Homepage";
import AgentLicense from "../../models/AgentLicense";
import {
  bundleAssociationPlansIDs,
  dentalAssociationPlansIDs,
  dentalPlansIDs,
  NCDCompleteByMetLife,
  NCDEssentialsByMetLife,
  NCDValueByMetLife,
  NCDValueByMetLifeUpdate,
  NCDElite1500,
  NCDElite3000,
  NCDElite5000,
  NCDSmile1000byMetLife,
  NCDSmile2500byMetLife,
  NCDValueByMetLifeNYCT,
  NCDEssentialsByMetLifeNYCT,
  NCDCompleteByMetLifeNYCT,
  notValidDentalAndNotValidVisionSteps,
  notValidDentalAndValidVisionSteps,
  nonValidEliteStates,
  nonValidSmileStates,
  nonValidSmileTopOfHierarchyIDs,
  sessionIDLambdaURL,
  validDentalAndNoValidVisionSteps,
  validDentalAndValidVisionSteps,
  visionAssociationPlansIDs,
  visionPlanIDs,
  visionPlanIDsForNonValidStates,
  dentalPlansIDsNYCT,
  aprilFirst,
} from "../../utils";
import Payment from "../../models/Payment";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { Header } from "../../components/Header";
import metlifeDentalData from "../../metlifeJsonData";
import eliteDentalData from "../../eliteDentalData";
import ncdSmileJsonData from "../../ncdSmileJsonData";
import { associationData } from "../../associationPlans";
import { visionData } from "../../vspVision";
import { CoverageType } from "../../enums";
import useApplicationStore from "../../context/application";

type FlowProps = {
  agent: Agent;
  memberInstance: Member;
  setMemberInstance: React.Dispatch<React.SetStateAction<Member>>;
};

const Flow: FC<FlowProps> = ({
  agent,
  memberInstance,
  setMemberInstance,
}: FlowProps) => {
  const [stepsNames, setStepsNames] = useState([
    "Start",
    "Dental Coverage",
    "Vision Coverage",
    "Amount of Dependents",
    "Dental",
    "Confirm Cart",
    "Primary",
    "Dependents",
    "Payment",
    "Confirmation",
    "Agreements",
    "Completed",
  ]);
  const [step, setStep] = useState(1);
  const [wantsDental, setWantsDental] = useState(false);
  const [wantsVision, setWantsVision] = useState(false);
  const [zipCodeState, setZipCodeState] = useState("");
  const [validDentalInState, setValidDentalInState] = useState(true);
  const [validVisionInState, setValidVisionInState] = useState(true);
  const [backgroundURL, setBackgroundURL] = useState("");
  const [dependents, setDependents] = useState<Dependent[]>([]);
  const [amountOfDependentsType, setAmountOfDependentsType] = useState("");
  const [amountOfDependents, setAmountOfDependents] = useState(0);
  const [dentalPlans, setDentalPlans] = useState<Plan[]>();
  const [visionPlans, setVisionPlans] = useState<Plan[]>();
  const [dentalAssociationPlans, setDentalAssociationPlans] =
    useState<Plan[]>();
  const [visionAssociationPlans, setVisionAssociationPlans] =
    useState<Plan[]>();
  const [dentalVisionAssociationPlans, setDentalVisionAssociationPlans] =
    useState<Plan[]>();
  const [associatedPlan, setAssociatedPlan] = useState<Plan>(new Plan());
  const [associatedPlanDental, setAssociatedPlanDental] = useState<Plan>(
    new Plan()
  );
  const [associationLeftover, setAssociationLeftover] = useState<number>(0);

  const [selectedPlanDental, setSelectedPlanDental] = useState<Plan>(
    new Plan()
  );
  const [selectedPlanVision, setSelectedPlanVision] = useState<Plan>(
    new Plan()
  );
  const [selectedPlans, setSelectedPlans] = useState<Plan[]>([]);
  const [agentState, setAgentState] = useState<Agent>();
  const [availableStatesForAgent, setAvailableStatesForAgent] = useState<
    AgentLicense[]
  >([]);

  const [member, setMember] = useState<Member>(memberInstance);
  const [payment, setPayment] = useState<Payment>(new Payment());

  const [generatedAppId, setGeneratedAppID] = useState<string>();
  const [loadingAppIdData, setLoadingAppIdData] = useState<boolean>(true);
  const [loadingPlans, setLoadingPlans] = useState<boolean>(false);

  const [billingErrorShown, setBillingErrorShown] = useState<boolean>(false);
  const [saveForLater, setSaveForLater] = useState<boolean>(false);
  const [purchaseState, setPurchaseState] = useState(false);

  const [applicationDate, setApplicationDate] = useApplicationStore((state) => [
    state.applicationDate,
    state.setApplicationDate,
  ]);

  const [max, setMax] = useState(0);

  const { agentId, appId } = useParams();
  const navigate = useNavigate();

  const clearState = () => {
    setWantsDental(false);
    setWantsVision(false);
    setZipCodeState("");
    setValidDentalInState(true);
    setValidVisionInState(true);
    setDependents([]);
    setAmountOfDependentsType("");
    setAmountOfDependents(0);
    setDentalPlans(undefined);
    setVisionPlans(undefined);
    setDentalAssociationPlans(undefined);
    setVisionAssociationPlans(undefined);
    setDentalVisionAssociationPlans(undefined);
    setAssociatedPlan(new Plan());
    setAssociatedPlanDental(new Plan());
    setSelectedPlanDental(new Plan());
    setSelectedPlanVision(new Plan());
    setSelectedPlans([]);
    setPayment(new Payment());
    setMax(0);
    setAssociationLeftover(0);
  };

  useEffect(() => {
    if (!appId) {
      setGeneratedAppID(uuid());
      setLoadingAppIdData(false);
    } else {
      setLoadingAppIdData(true);
      axios
        .get(sessionIDLambdaURL, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          params: {
            TableName: "metlife-applications",
            Key: appId,
          },
        })
        .then((response) => {
          let memberViaAppId = Object.assign(
            new Member(),
            JSON.parse(response?.data?.Item?.member)
          );
          let dentalPlansViaAppId = Object.assign(
            new Array<Plan>(),
            JSON.parse(response?.data?.Item?.availableDentalPlans)
          );
          let visionPlansViaAppId = Object.assign(
            new Array<Plan>(),
            JSON.parse(response?.data?.Item?.availableVisionPlans)
          );
          let dentalVisionAssociationPlansViaAppId = Object.assign(
            new Array<Plan>(),
            JSON.parse(
              response?.data?.Item?.availableDentalVisionAssociationPlans
            )
          );
          let dentalAssociationPlansViaAppId = Object.assign(
            new Array<Plan>(),
            JSON.parse(response?.data?.Item?.availableDentalAssociationPlans)
          );
          let visionAssociationPlansViaAppId = Object.assign(
            new Array<Plan>(),
            JSON.parse(response?.data?.Item?.availableVisionAssociationPlans)
          );
          ReactDOM.unstable_batchedUpdates(() => {
            setZipCodeState(response?.data?.Item?.state);
            setDentalPlans(dentalPlansViaAppId);
            setVisionPlans(visionPlansViaAppId);
            setDentalAssociationPlans(dentalAssociationPlansViaAppId);
            setVisionAssociationPlans(visionAssociationPlansViaAppId);
            setDentalVisionAssociationPlans(
              dentalVisionAssociationPlansViaAppId
            );
            setWantsDental(response?.data?.Item?.wantsDental);
            setWantsVision(response?.data?.Item?.wantsVision);
            setValidDentalInState(response?.data?.Item?.validDental);
            setValidVisionInState(response?.data?.Item?.validVision);
            setMember(memberViaAppId);
            setSelectedPlanDental(
              Object.assign(
                new Plan(),
                JSON.parse(response?.data?.Item?.planDental)
              )
            );
            setSelectedPlanVision(
              Object.assign(
                new Plan(),
                JSON.parse(response?.data?.Item?.planVision ?? "{}")
              )
            );
            setAssociatedPlan(
              Object.assign(
                new Plan(),
                JSON.parse(response?.data?.Item?.associationPlan)
              )
            );
            setAssociatedPlanDental(
              Object.assign(
                new Plan(),
                JSON.parse(
                  response?.data?.Item?.availableDentalVisionAssociationPlans
                )
              )
            );
            setDependents(
              Object.assign(
                new Array<Dependent>(),
                JSON.parse(response?.data?.Item?.dependents)
              )
            );
            setAmountOfDependentsType(
              response?.data?.Item?.amountOfDependentsType
            );

            setAmountOfDependents(response?.data?.Item?.amountOfDependents);

            setStep(response?.data?.Item?.step);
            setStepsNames(JSON.parse(response?.data?.Item?.stepsNames));
            setApplicationDate(response?.data?.Item?.applicationDate);
          });
        });
    }
    agent.loginAgent(parseInt(agentId!)).then(() => {
      setAgentState(agent);
      agent.getAgentLicense(parseInt(agentId!)).then((response) => {
        let availableStatesForAgent = new Array<AgentLicense>();
        JSON.parse(response)?.forEach(
          (element: { STATE: string; EXPIRATION_DATE: string }) => {
            availableStatesForAgent.push({
              state: element?.STATE,
              expiration_date: element?.EXPIRATION_DATE,
            });
          }
        );
        setAvailableStatesForAgent(availableStatesForAgent);
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (step <= 4) {
      setTimeout(() => {
        setLoadingAppIdData(false);
      }, 5000);
    } else if (
      appId &&
      zipCodeState &&
      dentalPlans &&
      visionPlans &&
      dentalAssociationPlans &&
      visionAssociationPlans &&
      dentalVisionAssociationPlans &&
      member &&
      selectedPlanDental &&
      selectedPlanVision &&
      associatedPlan &&
      dependents &&
      amountOfDependentsType &&
      stepsNames
    ) {
      setLoadingAppIdData(false);
    }
  }, [
    appId,
    zipCodeState,
    dentalPlans,
    visionPlans,
    visionAssociationPlans,
    dentalAssociationPlans,
    dentalVisionAssociationPlans,
    member,
    selectedPlanDental,
    selectedPlanVision,
    associatedPlan,
    dependents,
    amountOfDependentsType,
    stepsNames,
    step,
  ]);

  useEffect(() => {
    if (step >= 1 && !loadingAppIdData) {
      setDependents([]);
    }
  }, [amountOfDependentsType]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    memberInstance = member;
  }, [member]);

  useEffect(() => {
    if (!validDentalInState)
      setStepsNames((current) =>
        current.filter(
          (value) =>
            value !== "Dental Coverage" &&
            value !== "Amount Dental" &&
            value !== "Dental" &&
            value !== "Dependents Dental"
        )
      );
    if (!validVisionInState)
      setStepsNames((current) =>
        current.filter(
          (value) =>
            value !== "Amount Vision" &&
            value !== "Vision" &&
            value !== "Dependents Vision"
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validDentalInState, validVisionInState]);

  const matchOneOrTwoLeadingZeroesRegex = /^0{1,2}/;

  async function getPlanHandlerDental(
    plansIDs: number[],
    plansList: Plan[],
    setter: React.Dispatch<React.SetStateAction<Plan[] | undefined>>,
    dependentsType: string,
    zipCode: string | undefined,
    state?: string
  ) {
    plansIDs.forEach(async (id) => {
      const newPlan = new Plan();
      // await plan
      //   .getPlanDetails(id, dependentsType, agent?.id, zipCode, state)
      //   .then(() => {
      //     plansList.push(plan);
      //     plansList.sort((plan, secondPlan) => {
      //       if (plan.rate && secondPlan.rate)
      //         return plan.rate - secondPlan.rate;
      //       return 0;
      //     });
      //   });

      metlifeDentalData?.forEach((plan, index) => {
        if (
          id === NCDValueByMetLife &&
          plan?.ProductID === id &&
          dependentsType === plan?.AmountOfPeople
        ) {
          newPlan.id = plan?.ProductID;
          newPlan.benefitID = plan?.BenefitID;
          newPlan.benefitLabel = plan?.AmountOfPeople;
          newPlan.label = plan?.Label;
          newPlan.index = index;

          if (applicationDate + 1 >= aprilFirst) {
            newPlan.rate = plan?.PriceUpdated;
          } else {
            newPlan.rate = plan?.Price;
          }

          plansList.push(newPlan);
          plansList.sort((plan, secondPlan) => {
            if (plan.rate && secondPlan.rate)
              return plan.rate - secondPlan.rate;
            return 0;
          });
        } else if (
          plan?.ProductID === id &&
          zipCode?.slice(0, 3).replace(matchOneOrTwoLeadingZeroesRegex, "") === plan?.Zipcode?.toString() &&
          dependentsType === plan?.AmountOfPeople
        ) {
          newPlan.id = plan?.ProductID;
          newPlan.benefitID = plan?.BenefitID;
          newPlan.benefitLabel = plan?.AmountOfPeople;
          newPlan.label = plan?.Label;
          newPlan.index = index;

          if (applicationDate + 1 >= aprilFirst) {
            newPlan.rate = plan?.PriceUpdated;
          } else {
            newPlan.rate = plan?.Price;
          }

          plansList.push(newPlan);
          plansList.sort((plan, secondPlan) => {
            if (plan.rate && secondPlan.rate)
              return plan.rate - secondPlan.rate;
            return 0;
          });
        }
      });

      if (!nonValidEliteStates.includes(zipCodeState)) {
        eliteDentalData?.forEach((plan) => {
          if (
            plan?.ProductID === id &&
            zipCode?.slice(0, 3).replace(matchOneOrTwoLeadingZeroesRegex, "") ===
            plan?.Zipcode.toString() &&
            dependentsType === plan?.AmountOfPeople
          ) {
            newPlan.id = plan?.ProductID;
            newPlan.benefitID = plan?.BenefitID;
            newPlan.benefitLabel = plan?.AmountOfPeople;
            newPlan.rate = plan?.Price;
            newPlan.label = plan?.Label;
            plansList.push(newPlan);
          }
        });
      }

      if (!nonValidSmileStates.includes(zipCodeState)) {
        ncdSmileJsonData?.forEach((plan) => {
          if (
            agent.parentAgentId !== undefined &&
            !nonValidSmileTopOfHierarchyIDs.includes(agent.parentAgentId) &&
            plan?.ProductID === id &&
            zipCode?.slice(0, 3).replace(matchOneOrTwoLeadingZeroesRegex, "").replace(matchOneOrTwoLeadingZeroesRegex, "") ===
            plan?.Zipcode.toString() &&
            dependentsType === plan?.AmountOfPeople
          ) {
            newPlan.id = plan?.ProductID;
            newPlan.benefitID = plan?.BenefitID;
            newPlan.benefitLabel = plan?.AmountOfPeople;
            newPlan.rate = plan?.Price;
            newPlan.label = plan?.Label;
            plansList.push(newPlan);
          }
        });
      };
      setter(plansList);
    });
  };

  async function getPlanHandlerDentalNYCT(
    plansIDs: number[],
    plansList: Plan[],
    setter: React.Dispatch<React.SetStateAction<Plan[] | undefined>>,
    dependentsType: string,
    zipCode: string | undefined,
    state?: string
  ) {
    plansIDs.forEach(async (id) => {
      const newPlan = new Plan();
      // await plan
      //   .getPlanDetails(id, dependentsType, agent?.id, zipCode, state)
      //   .then(() => {
      //     plansList.push(plan);
      //     plansList.sort((plan, secondPlan) => {
      //       if (plan.rate && secondPlan.rate)
      //         return plan.rate - secondPlan.rate;
      //       return 0;
      //     });
      //   });
      metlifeDentalData?.forEach((plan, index) => {
        if (
          id === NCDValueByMetLifeNYCT &&
          plan?.ProductID === id &&
          zipCodeState === plan?.State &&
          dependentsType === plan?.AmountOfPeople
        ) {
          newPlan.id = plan?.ProductID;
          newPlan.benefitID = plan?.BenefitID;
          newPlan.benefitLabel = plan?.AmountOfPeople;
          newPlan.label = plan?.Label;
          newPlan.index = index;

          if (applicationDate + 1 >= aprilFirst) {
            newPlan.rate = plan?.PriceUpdated;
          } else {
            newPlan.rate = plan?.Price;
          }

          plansList.push(newPlan);
          plansList.sort((plan, secondPlan) => {
            if (plan.rate && secondPlan.rate)
              return plan.rate - secondPlan.rate;
            return 0;
          });
        } else if (
          plan?.ProductID === id &&
          zipCode?.slice(0, 3).replace(matchOneOrTwoLeadingZeroesRegex, "") === plan?.Zipcode?.toString() &&
          dependentsType === plan?.AmountOfPeople
        ) {
          newPlan.id = plan?.ProductID;
          newPlan.benefitID = plan?.BenefitID;
          newPlan.benefitLabel = plan?.AmountOfPeople;
          newPlan.label = plan?.Label;
          newPlan.index = index;

          if (applicationDate + 1 >= aprilFirst) {
            newPlan.rate = plan?.PriceUpdated;
          } else {
            newPlan.rate = plan?.Price;
          }

          plansList.push(newPlan);
          plansList.sort((plan, secondPlan) => {
            if (plan.rate && secondPlan.rate)
              return plan.rate - secondPlan.rate;
            return 0;
          });
        }
      });

      if (!nonValidSmileStates.includes(zipCodeState)) {
        ncdSmileJsonData?.forEach((plan) => {
          if (
            agent.parentAgentId !== undefined &&
            !nonValidSmileTopOfHierarchyIDs.includes(agent.parentAgentId) &&
            plan?.ProductID === id &&
            zipCode?.slice(0, 3).replace(matchOneOrTwoLeadingZeroesRegex, "").replace(matchOneOrTwoLeadingZeroesRegex, "") ===
            plan?.Zipcode.toString() &&
            dependentsType === plan?.AmountOfPeople
          ) {
            newPlan.id = plan?.ProductID;
            newPlan.benefitID = plan?.BenefitID;
            newPlan.benefitLabel = plan?.AmountOfPeople;
            newPlan.rate = plan?.Price;
            newPlan.label = plan?.Label;
            plansList.push(newPlan);
          }
        });
      };

      if (!nonValidEliteStates.includes(zipCodeState)) {
        eliteDentalData?.forEach((plan) => {
          if (
            plan?.ProductID === id &&
            zipCode?.slice(0, 3).replace(matchOneOrTwoLeadingZeroesRegex, "") ===
            plan?.Zipcode.toString() &&
            dependentsType === plan?.AmountOfPeople
          ) {
            newPlan.id = plan?.ProductID;
            newPlan.benefitID = plan?.BenefitID;
            newPlan.benefitLabel = plan?.AmountOfPeople;
            newPlan.rate = plan?.Price;
            newPlan.label = plan?.Label;
            plansList.push(newPlan);
          }
        });
      }
    });
    setter(plansList);
  }

  async function getPlanHandlerVision(
    plansIDs: number[],
    plansList: Plan[],
    setter: React.Dispatch<React.SetStateAction<Plan[] | undefined>>,
    dependentsType: string,
    zipCode: string | undefined,
    dependents: Dependent[],
    state?: string
  ) {
    let visionDependents: CoverageType;

    if (dependents.length >= 2) {
      visionDependents = CoverageType.Family;
    } else {
      visionDependents = [
        CoverageType.MemberPlusSpouse,
        CoverageType.MemberPlusChildren,
      ].includes(dependentsType as CoverageType)
        ? CoverageType.MemberPlusOne
        : (dependentsType as CoverageType);
    }

    plansIDs.forEach(async (id) => {
      const newPlan = new Plan();
      // await plan
      //   .getPlanDetails(id, dependentsType, agent?.id, zipCode, state)
      //   .then(() => {
      //     plansList.push(plan);
      //     plansList.sort((plan, secondPlan) => {
      //       if (plan.rate && secondPlan.rate)
      //         return plan.rate - secondPlan.rate;
      //       return 0;
      //     });
      //   });
      visionData?.forEach((plan) => {
        if (state !== "NY" && state !== "OR") {
          if (
            plan?.ProductID === id &&
            visionDependents === plan?.AmountOfPeople
          ) {
            newPlan.id = plan?.ProductID;
            newPlan.benefitID = plan?.BenefitID;
            newPlan.benefitLabel = plan?.AmountOfPeople;
            newPlan.rate = plan?.Price;
            newPlan.label = plan?.Label;
            plansList.push(newPlan);
            plansList.sort((plan, secondPlan) => {
              if (plan.rate && secondPlan.rate)
                return plan.rate - secondPlan.rate;
              return 0;
            });
          }
        } else {
          if (
            plan?.ProductID === id &&
            visionDependents === plan?.AmountOfPeople &&
            state === plan?.State
          ) {
            newPlan.id = plan?.ProductID;
            newPlan.benefitID = plan?.BenefitID;
            newPlan.benefitLabel = plan?.AmountOfPeople;
            newPlan.rate = plan?.Price;
            newPlan.label = plan?.Label;
            plansList.push(newPlan);
            plansList.sort((plan, secondPlan) => {
              if (plan.rate && secondPlan.rate)
                return plan.rate - secondPlan.rate;
              return 0;
            });
          }
        }
      });
    });
    setter(plansList);
  }

  async function getPlanHandlerAssociation(
    plansIDs: number[],
    plansList: Plan[],
    setter: React.Dispatch<React.SetStateAction<Plan[] | undefined>>,
    dependentsType: string,
    zipCode: string | undefined,
    dependents: Dependent[],
    state?: string
  ) {
    let visionDependents: CoverageType;

    if (dependentsType === CoverageType.MemberPlusChildren) {
      visionDependents = CoverageType.Family;
    } else {
      visionDependents = [
        CoverageType.MemberPlusSpouse,
        CoverageType.MemberPlusChildren,
      ].includes(dependentsType as CoverageType)
        ? CoverageType.MemberPlusOne
        : (dependentsType as CoverageType);
    }

    plansIDs.forEach(async (id) => {
      const newPlan = new Plan();
      // await plan
      //   .getPlanDetails(id, dependentsType, agent?.id, zipCode, state)
      //   .then(() => {
      //     plansList.push(plan);
      //     plansList.sort((plan, secondPlan) => {
      //       if (plan.rate && secondPlan.rate)
      //         return plan.rate - secondPlan.rate;
      //       return 0;
      //     });
      //   });
      associationData?.forEach((plan) => {
        if (id === 38923) {
          if (
            plan?.ProductID === id &&
            visionDependents === plan?.AmountOfPeople
          ) {
            newPlan.id = plan?.ProductID;
            newPlan.benefitID = plan?.BenefitID;
            newPlan.benefitLabel = plan?.AmountOfPeople;
            newPlan.rate = plan?.Price;
            newPlan.rateUpdate = plan?.PriceUpdated;
            newPlan.label = plan?.Label;
            plansList.push(newPlan);
            plansList.sort((plan, secondPlan) => {
              if (plan.rate && secondPlan.rate)
                return plan.rate - secondPlan.rate;
              return 0;
            });
          }
        } else if (
          plan?.ProductID === id &&
          dependentsType === plan?.AmountOfPeople
        ) {
          newPlan.id = plan?.ProductID;
          newPlan.benefitID = plan?.BenefitID;
          newPlan.benefitLabel = plan?.AmountOfPeople;
          newPlan.rate = plan?.Price;
          newPlan.rateUpdate = plan?.PriceUpdated;
          newPlan.label = plan?.Label;
          plansList.push(newPlan);
          plansList.sort((plan, secondPlan) => {
            if (plan.rate && secondPlan.rate)
              return plan.rate - secondPlan.rate;
            return 0;
          });
        }
      });
    });
    setter(plansList);
  }

  async function getAvailablePlans(
    validDental: boolean,
    validVision: boolean,
    state?: string,
    memberViaAppID?: Member,
    amountOfDependentsTypeViaAppID?: string
  ) {
    const dentalPlans: Plan[] = [];
    const visionPlans: Plan[] = [];
    const dentalAssociationFee: Plan[] = [];
    const visionAssociationFee: Plan[] = [];
    const dentalAndVisionAssociationFee: Plan[] = [];
    let zipCode = memberViaAppID ? memberViaAppID?.zipCode : member?.zipCode;
    let dependentsType = amountOfDependentsTypeViaAppID
      ? amountOfDependentsTypeViaAppID
      : amountOfDependentsType;
    if (validDental) {
      if (state !== "NY" && state !== "CT") {
        getPlanHandlerDental(
          dentalPlansIDs,
          dentalPlans,
          setDentalPlans,
          dependentsType,
          zipCode
        );
      } else {
        getPlanHandlerDentalNYCT(
          dentalPlansIDsNYCT,
          dentalPlans,
          setDentalPlans,
          dependentsType,
          zipCode,
          zipCodeState
        );
      }
      getPlanHandlerAssociation(
        dentalAssociationPlansIDs,
        dentalAssociationFee,
        setDentalAssociationPlans,
        dependentsType,
        zipCode,
        dependents
      );
    }
    if (validVision)
      getPlanHandlerVision(
        visionPlanIDs,
        visionPlans,
        setVisionPlans,
        dependentsType,
        zipCode,
        dependents
      );
    else {
      getPlanHandlerVision(
        visionPlanIDsForNonValidStates,
        visionPlans,
        setVisionPlans,
        dependentsType,
        zipCode,
        dependents,
        state
      );
    }
    getPlanHandlerAssociation(
      visionAssociationPlansIDs,
      visionAssociationFee,
      setVisionAssociationPlans,
      dependentsType,
      zipCode,
      dependents
    );
    getPlanHandlerAssociation(
      bundleAssociationPlansIDs,
      dentalAndVisionAssociationFee,
      setDentalVisionAssociationPlans,
      dependentsType,
      zipCode,
      dependents
    );
    setLoadingPlans(false);
  }

  // This useEffect will re-render the plans
  useEffect(() => {
    if (loadingAppIdData) return;
    setLoadingPlans(true);
    getAvailablePlans(validDentalInState, validVisionInState, zipCodeState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountOfDependentsType, dependents, loadingAppIdData, applicationDate, agentState]);

  useEffect(() => {
    if (visionPlans && wantsVision) {
      setSelectedPlanVision(visionPlans[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visionPlans, wantsVision, applicationDate]);

  useEffect(() => {
    setSelectedPlans(
      (current) => (current = [selectedPlanDental, selectedPlanVision])
    );
    let associationProduct;
    let associationProductDental;

    if (!selectedPlanDental?.id && selectedPlanVision?.id) {
      if (visionAssociationPlans) {
        associationProduct = visionAssociationPlans[0];
      }
    } else if (!selectedPlanVision?.id && selectedPlanDental?.id) {
      switch (selectedPlanDental?.id) {
        case NCDValueByMetLife:
        case NCDValueByMetLifeNYCT:
        case NCDSmile1000byMetLife:
        case NCDSmile2500byMetLife:
          if (dentalAssociationPlans) {
            associationProduct = dentalAssociationPlans[0];
          }

          break;
        case NCDEssentialsByMetLife:
        case NCDEssentialsByMetLifeNYCT:
        case NCDCompleteByMetLife:
        case NCDCompleteByMetLifeNYCT:
        case NCDElite1500:
        case NCDElite3000:
        case NCDElite5000:
          if (dentalAssociationPlans) {
            associationProduct = dentalAssociationPlans[1];
          }
          break;
        default:
          break;
      }
    } else {
      switch (selectedPlanDental?.id) {
        case NCDValueByMetLife:
        case NCDValueByMetLifeNYCT:
        case NCDSmile1000byMetLife:
        case NCDSmile2500byMetLife:
          if (dentalVisionAssociationPlans && dentalAssociationPlans) {
            associationProduct = dentalVisionAssociationPlans[0];
            associationProductDental = dentalAssociationPlans[0];
          }
          break;
        case NCDEssentialsByMetLife:
        case NCDEssentialsByMetLifeNYCT:
        case NCDCompleteByMetLife:
        case NCDCompleteByMetLifeNYCT:
        case NCDElite1500:
        case NCDElite3000:
        case NCDElite5000:
          if (dentalVisionAssociationPlans && dentalAssociationPlans) {
            associationProduct = dentalVisionAssociationPlans[1];
            associationProductDental = dentalAssociationPlans[1];
          }
          break;
        default:
          break;
      }
    }

    if (associationProduct && step >= 1 && !loadingAppIdData) {
      setAssociatedPlan(associationProduct);
      if (associationProductDental)
        setAssociatedPlanDental(associationProductDental);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlanDental, selectedPlanVision, associatedPlan, applicationDate]);

  function getAssociationPlans(): Plan[] | undefined {
    if (wantsDental && wantsVision) return dentalVisionAssociationPlans;
    if (wantsDental && !wantsVision) return dentalAssociationPlans;
    if (!wantsDental && wantsVision) return visionAssociationPlans;
    else return undefined;
  }

  const renderStep = () => {
    switch (stepsNames[step - 1]) {
      case "Start":
        return (
          <HomePage
            member={member}
            agent={agent}
            setValidDentalInState={setValidDentalInState}
            setValidVisionInState={setValidVisionInState}
            setZipCodeState={setZipCodeState}
            setStep={setStep}
          />
        );
      case "Amount of Dependents":
        return (
          <HowManyPeople
            amountOfDependents={amountOfDependents}
            setAmountOfDependents={setAmountOfDependents}
            setAmountOfDependentsType={setAmountOfDependentsType}
            setStep={setStep}
            setStepsNames={setStepsNames}
          />
        );
      case "Dental Coverage":
        return (
          <Coverage
            type="Dental"
            setStep={setStep}
            setStepsNames={setStepsNames}
            setSelectedPlan={setSelectedPlanDental}
            setWants={setWantsDental}
          />
        );
      case "Vision Coverage":
        return (
          <Coverage
            type="Vision"
            setStep={setStep}
            setStepsNames={setStepsNames}
            setSelectedPlan={setSelectedPlanVision}
            setWants={setWantsVision}
          />
        );
      case "Dental":
        return (
          <SelectPlan
            agent={agent}
            dependents={dependents}
            key={"dental"}
            setSelectedPlan={setSelectedPlanDental}
            plansProp={dentalPlans}
            associationPlans={getAssociationPlans()}
            associatedPlanDental={associatedPlanDental}
            dentalAssociationPlans={dentalAssociationPlans}
            type="Dental"
            setStep={setStep}
            selectedPlans={selectedPlans}
            wantsDental={wantsDental}
            wantsVision={wantsVision}
            zipCodeState={zipCodeState}
            max={max}
            setMax={setMax}
            member={member}
            associationLeftover={associationLeftover}
            setAssociationLeftover={setAssociationLeftover}
          />
        );
      case "Confirm Cart":
        return (
          <ConfirmCart
            member={member}
            agent={agentState || agent}
            selectedPlans={selectedPlans}
            wantsDental={wantsDental}
            wantsVision={wantsVision}
            associatedPlan={associatedPlan}
            associatedPlanDental={associatedPlanDental}
            dentalAssociationPlans={dentalAssociationPlans}
            dependents={dependents}
            amountOfDependentsType={amountOfDependentsType}
            setStep={setStep}
            zipCodeState={zipCodeState}
            max={max}
            setMax={setMax}
            validVisionInState={validVisionInState}
            associationLeftover={associationLeftover}
            setAssociationLeftover={setAssociationLeftover}
          />
        );

      case "Primary":
        return (
          <PrimaryInformation
            agent={agentState || agent}
            member={member}
            payment={payment}
            setMember={setMember}
            setPayment={setPayment}
            setStep={setStep}
            zipCodeState={zipCodeState}
          />
        );
      case "Dependents":
        return (
          <DependentInformation
            key={":dependantInformation:wrapper"}
            member={member}
            agent={agent}
            setAmountOfDependents={setAmountOfDependents}
            amountOfDependents={amountOfDependents}
            amountOfDependentsType={amountOfDependentsType}
            setDependents={setDependents}
            setSelectedPlanVision={setSelectedPlanVision}
            validVisionInState={validVisionInState}
            dependents={dependents}
            setStep={setStep}
            selectedPlans={selectedPlans}
            zipCodeState={zipCodeState}
          />
        );
      case "Payment":
        return (
          <PaymentInformation
            member={member}
            payment={payment}
            setPayment={setPayment}
            setStep={setStep}
          />
        );
      case "Confirmation":
        return (
          <Confirmation
            selectedPlans={selectedPlans}
            associatedPlan={associatedPlan}
            dependents={dependents}
            member={member}
            agent={agentState || agent}
            wantsDental={wantsDental}
            setSelectedPlans={setSelectedPlans}
            setStep={setStep}
          />
        );
      case "Agreements":
        return (
          <Agreements
            memberInstance={memberInstance}
            setMemberInstance={setMemberInstance}
            agent={agentState || agent}
            member={member}
            selectedPlans={selectedPlans}
            associatedPlan={associatedPlan}
            dependents={dependents}
            zipCodeState={zipCodeState}
            availableStatesForAgent={availableStatesForAgent}
            payment={payment}
            setSaveForLater={setSaveForLater}
            generatedAppId={generatedAppId || ""}
            appId={appId || ""}
            step={step}
            stepsNames={stepsNames}
            wantsDental={wantsDental}
            wantsVision={wantsVision}
            validDental={validDentalInState}
            validVision={validVisionInState}
            dentalPlans={dentalPlans}
            visionPlans={visionPlans}
            dentalAssociationPlans={dentalAssociationPlans}
            visionAssociationPlans={visionPlans}
            dentalVisionAssociationPlans={dentalVisionAssociationPlans}
            amountOfDependents={amountOfDependents}
            amountOfDependentsType={amountOfDependentsType}
            planDental={selectedPlanDental}
            planVision={selectedPlanVision}
            setStep={setStep}
            billingErrorShown={billingErrorShown}
            setBillingErrorShown={setBillingErrorShown}
            setPurchaseState={setPurchaseState}
          />
        );
      default:
        return <div>Work In Progress</div>;
    }
  };

  const renderImage = () => {
    if (window.innerWidth > 390)
      switch (stepsNames[step - 1]) {
        case "Dental Coverage":
          return setBackgroundURL(
            `linear-gradient(90deg, rgba(0, 55, 100, 0.75) 0%, rgba(83, 191, 231, 0.75) 100%), url("/dentalBackground.jpg")`
          );
        case "Vision Coverage":
          return setBackgroundURL(
            `linear-gradient(90deg, rgba(0, 55, 100, 0.75) 0%, rgba(83, 191, 231, 0.75) 100%), url("/visionBackground.jpg")`
          );
        case "Dental":
          return setBackgroundURL(
            "linear-gradient(90deg, rgba(0, 55, 100, 0.5) 0%, rgba(83, 191, 231, 0.5) 100%"
          );
        case "Confirm Cart":
          return setBackgroundURL(
            "linear-gradient(90deg, rgba(0, 55, 100, 0.5) 0%, rgba(83, 191, 231, 0.5) 100%"
          );
        default:
          setBackgroundURL("linear-gradient(to right, #003764, #53BFE7)");
      }
    else {
      switch (stepsNames[step - 1]) {
        case "Dental Coverage":
          return setBackgroundURL(
            `linear-gradient(90deg, rgba(0, 55, 100, 0.75) 0%, rgba(83, 191, 231, 0.75) 100%), url("/dental1.jpg")`
          );
        case "Vision Coverage":
          return setBackgroundURL(
            `linear-gradient(90deg, rgba(0, 55, 100, 0.75) 0%, rgba(83, 191, 231, 0.75) 100%), url("/vision1.jpg")`
          );
        case "Dental":
          return setBackgroundURL(
            "linear-gradient(90deg, rgba(0, 55, 100, 0.5) 0%, rgba(83, 191, 231, 0.5) 100%"
          );
        case "Confirm Cart":
          return setBackgroundURL(
            "linear-gradient(90deg, rgba(0, 55, 100, 0.5) 0%, rgba(83, 191, 231, 0.5) 100%"
          );

        default:
          setBackgroundURL("linear-gradient(to right, #003764, #53BFE7)");
      }
    }
  };

  useEffect(() => {
    renderImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth, stepsNames]);

  useEffect(() => {
    renderImage();
    if (step <= 5) {
      let newSteps: string[] = [];
      if (validDentalInState && validVisionInState) {
        newSteps = validDentalAndValidVisionSteps;
      }
      if (!validDentalInState && validVisionInState) {
        newSteps = notValidDentalAndValidVisionSteps;
      }
      if (validDentalInState && !validVisionInState) {
        newSteps = validDentalAndNoValidVisionSteps;
      }
      if (!validDentalInState && !validVisionInState) {
        newSteps = notValidDentalAndNotValidVisionSteps;
      }
      if (amountOfDependentsType === CoverageType.Member) {
        newSteps = newSteps.filter((current) => current !== "Dependents");
      }
      if (!wantsDental && step > 1) {
        newSteps = newSteps.filter(
          (step) => step !== "Dental" && step !== "Vision Coverage"
        );
      }
      if (!wantsVision && step > 3) {
        newSteps = newSteps.filter((step) => step !== "Vision");
      }
      setStepsNames(newSteps);
    }
    {
      /* 
    if (
      stepsNames[step - 1] === "Dental Coverage" ||
      stepsNames[step - 1] === "Start"
    ) {
      setSelectedPlanVision(new Plan());
    }
    */
    }
    if (stepsNames[step - 1] === "Start") {
      clearState();
    }

    if (step <= 2) {
      setDependents([]);
      setWantsDental(false);
      setWantsVision(false);
    }

    if (step > 1 && !wantsDental) {
      setWantsVision(true);
    }

    if (stepsNames[step - 1] === "Amount of Dependents" || step < 3) {
      setAssociatedPlan(new Plan());
    }
    if (step >= 1 && !loadingAppIdData) {
      let memberToSave = member;
      memberToSave.payment = undefined;
      let memberValue = JSON.stringify(memberToSave);
      let dependentsValue = JSON.stringify(dependents);
      let planDentalValue = JSON.stringify(selectedPlanDental);
      let planVisionValue = JSON.stringify(selectedPlanVision);
      let associationPlanValue = JSON.stringify(associatedPlan);
      let associationPlanFullValue = JSON.stringify(associatedPlanDental);
      let stepsNamesValue = JSON.stringify(stepsNames);
      let availableDentalPlansValue = JSON.stringify(dentalPlans);
      let availableVisionPlansValue = JSON.stringify(visionPlans);
      let availableDentalAssociationPlansValue = JSON.stringify(
        dentalAssociationPlans
      );
      let availableVisionAssociationPlansValue = JSON.stringify(visionPlans);
      let availableDentalVisionAssociationPlansValue = JSON.stringify(
        dentalVisionAssociationPlans
      );

      axios
        .post(
          sessionIDLambdaURL,
          {
            TableName: "metlife-applications",
            Item: {
              app_id: generatedAppId ? generatedAppId : appId,
              step: step,
              state: zipCodeState,
              wantsDental: wantsDental,
              wantsVision: wantsVision,
              validDental: validDentalInState,
              validVision: validVisionInState,
              availableDentalPlans: availableDentalPlansValue,
              availableVisionPlans: availableVisionPlansValue,
              availableDentalVisionAssociationPlans:
                availableDentalVisionAssociationPlansValue,
              availableDentalAssociationPlans:
                availableDentalAssociationPlansValue,
              availableVisionAssociationPlans:
                availableVisionAssociationPlansValue,
              member: memberValue,
              dependents: dependentsValue,
              amountOfDependentsType: amountOfDependentsType,
              amountOfDependents: amountOfDependents,
              planDental: planDentalValue,
              planVision: planVisionValue,
              associationPlan: associationPlanValue,
              associatedPlanDental: associationPlanFullValue,
              stepsNames: stepsNamesValue,
              applicationDate: applicationDate,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then(() => {
          if (generatedAppId && step >= 1) {
            navigate(`/${agentId}/${generatedAppId}`);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  let visionId = validVisionInState ? 38593 : 39651;
  useEffect(() => {
    member.state = zipCodeState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCodeState]);

  return (
    <div className="relative h-full">
      <div
        className={`z-0 flex min-h-screen w-full flex-col items-center bg-cover bg-center bg-no-repeat ${backgroundURL && "bg-opacity-75"
          }`}
        style={{
          backgroundImage: backgroundURL,
        }}
      >
        <div className="sticky top-0 z-50 w-full">
          <Header agentState={agentState} />
        </div>
        <div className="relative z-40 mb-[100px] flex flex-col items-center justify-center font-inter">
          {loadingAppIdData || loadingPlans ? (
            <div className="flex w-full items-center justify-center self-center text-center text-4xl text-navyBlue lg:h-[70vh]">
              <p>LOADING...</p>
            </div>
          ) : (
            renderStep()
          )}
        </div>
        <div
          className={`fixed bottom-0 z-50 ml-auto mr-auto w-full bg-orange pt-1`}
        >
          <ProgressBar
            step={step}
            purchaseState={purchaseState}
            setStep={setStep}
          />
        </div>
      </div>
    </div>
  );
};

export default Flow;
