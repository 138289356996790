interface IErrorLogEntry {
  error: any;
  message: string;
  level: LogLevel;
}
interface IErrorLog {
  entries: IErrorLogEntry[];
}

export enum LogLevel {
  debug, info, warn, error, fatal, unknown
}

// ErrorLog class abstracts away how we log and retain errors
// Uses the Singleton creational pattern so that we only instantiate one instance of the ErrorLog
export default class ErrorLog implements IErrorLog {
  private static instance: ErrorLog;
  entries: IErrorLogEntry[];

  private constructor() {
    this.entries = [];
  }

  public static getInstance(): ErrorLog {
    if (!ErrorLog.instance) {
      ErrorLog.instance = new ErrorLog();
    }

    return ErrorLog.instance;
  }

  logError(error: any, message: string, level = LogLevel.warn ) {
    this.entries.push(new ErrorLogEntry(error, message, level))

    // We could take different logging approaches based on "level":
    console.error(message, error);
  }

  public static lastEntry(): ErrorLogEntry | undefined {
    return this.getInstance().entries[this.getInstance().entries.length - 1]
  }
}

class ErrorLogEntry implements IErrorLogEntry {
  message: string;
  error: any;
  level: LogLevel;

  constructor(error: any, message: string, level = LogLevel.warn ) {
    this.error = error;
    this.message = message;
    this.level = level;
  }
}