import ErrorLog, { LogLevel } from "./models/ErrorLog";

export const getEnvKey = (key: string): string | undefined => {
  const value = process.env[key];
  if (value === undefined) {
    const message = `Missing required environment variable ${key}`
    ErrorLog.getInstance().logError(new Error(message), message, LogLevel.warn)
  }
  return value;
}
export const getEnv = () => {
  return getEnvKey('NODE_ENV')
}
