import React, { FC, useCallback, useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import Agent from "../../models/Agent";
import Dependent from "../../models/Dependent";
import Member, { ICreateMemberResponse } from "../../models/Member";
import Payment from "../../models/Payment";
import Plan from "../../models/Plan";
import AgentLicense from "../../models/AgentLicense";
import { D2CAgentId, formatDate, formatDateString, isBillingCurrent, svgBillingDateCheck, SVGUplines } from "../../utils";
import useApplicationStore from "../../context/application";
import BillingDateModal from "../BillingDateModal";
import ErrorModal from "../../components/Modals/ErrorModal";
import AgreementForm from "./AgreementForm";
import axios from "axios";
import Submitted from "./Submitted";
import ErrorLog, { LogLevel } from "../../models/ErrorLog";

type AgreementsProps = {
  agent: Agent;
  member: Member;
  memberInstance: Member;
  payment: Payment;
  selectedPlans: Plan[];
  associatedPlan: Plan;
  zipCodeState: string;
  dependents: Dependent[];
  availableStatesForAgent: AgentLicense[];
  billingErrorShown: boolean;
  generatedAppId: string;
  appId: string;
  step: number;
  stepsNames: string[];
  wantsDental: boolean;
  wantsVision: boolean;
  validDental: boolean;
  validVision: boolean;
  dentalPlans: Plan[] | undefined;
  visionPlans: Plan[] | undefined;
  dentalAssociationPlans: Plan[] | undefined;
  visionAssociationPlans: Plan[] | undefined;
  dentalVisionAssociationPlans: Plan[] | undefined;
  amountOfDependents: number;
  amountOfDependentsType: string;
  planDental: Plan;
  planVision: Plan;
  setSaveForLater: React.Dispatch<React.SetStateAction<boolean>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setMemberInstance: React.Dispatch<React.SetStateAction<Member>>;
  setBillingErrorShown: React.Dispatch<React.SetStateAction<boolean>>;
  setPurchaseState: React.Dispatch<React.SetStateAction<boolean>>;
};

const Agreements: FC<AgreementsProps> = ({
  agent,
  member,
  memberInstance,
  selectedPlans,
  associatedPlan,
  dependents,
  zipCodeState,
  availableStatesForAgent,
  payment,
  billingErrorShown,
  generatedAppId,
  appId,
  step,
  stepsNames,
  wantsDental,
  wantsVision,
  validDental,
  validVision,
  dentalPlans,
  visionPlans,
  dentalAssociationPlans,
  visionAssociationPlans,
  dentalVisionAssociationPlans,
  amountOfDependents,
  amountOfDependentsType,
  planDental,
  planVision,
  setSaveForLater,
  setStep,
  setMemberInstance,
  setBillingErrorShown,
  setPurchaseState,
}: AgreementsProps) => {
  const { agentId } = useParams();

  const [agreement, setAgreement] = useState(false);
  const [agreementTwo, setAgreementTwo] = useState(Number(agent?.id) !== D2CAgentId);
  const [didNotAgreeToTheTerms, setDidNotAgreeToTheTerms] = useState(false);
  const [invalidBillingDate, setInvalidBillingDate] = useState(false);

  const [invalidAnimation, setInvalidAnimation] = useState(false);
  const [submitState, setSubmitState] = useState({
    loading: false,
  });

  const setApplicationDate = useApplicationStore((state) => state.setApplicationDate );

  function getFirstDayOfMonth(year: number, month: number) {
    return new Date(year, month, 1);
  }

  const handleIncorrectBillingDate = () => {
    // Hide Modal
    setInvalidBillingDate(false);

    // Update billing date
    member.billingDate = formatDateString(formatDate(new Date()));

    const billingDateObj = new Date(billingDate);
    const nextMonth = getFirstDayOfMonth(billingDateObj.getFullYear(), billingDateObj.getMonth() + 1)
    const monthAfterNext = getFirstDayOfMonth(billingDateObj.getFullYear(), billingDateObj.getMonth() + 2)

    // Check if effective date is greater or equal to the first of the following month
    if (member.effectiveDate) {
      if (new Date(member.effectiveDate) <= nextMonth) {
        member.effectiveDate = formatDate(monthAfterNext);
      }
    }

  };

  useEffect(() => {
    setAgreementTwo(Number(agent?.id) !== D2CAgentId);
  }, [agent]);

  useEffect(() => {
    let value = 0;
    [...selectedPlans, associatedPlan].forEach((plan) => {
      if (plan?.rate) {
        value = value + plan?.rate;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlans]);

  useEffect(() => {
    const tmpMember = new Member(
      member.corpID,
      member.agent ?? agent,
      memberInstance.id || member.id,
      member.createdDate,
      member.firstName,
      member.middleName,
      member.lastName,
      member.dateOfBirth,
      member.gender,
      member.address1,
      member.address2,
      member.city,
      member.state,
      member.zipCode,
      member.otherAddress1,
      member.otherAddress2,
      member.otherCity,
      member.otherState,
      member.otherZipCode,
      member.email,
      member.phone1,
      member.phone2,
      member.phone3,
      dependents,
      payment,
      member.billingDate === formatDate(new Date()) ? "Y" : "N",
      member.billingDate,
      member.effectiveDate
    );
    setMemberInstance(tmpMember);
  }, []);

  function updateBillingandPaymentProcess(newBillingDate: string) {
    const isToday = newBillingDate === formatDate(new Date());

    const updatedValues = {
      billingDate: newBillingDate,
      paymentProcess: isToday ? "Y" : "N",
    }

    Object.assign(member, updatedValues);
    Object.assign(memberInstance, updatedValues);
  }

  async function svgHandleBillingUpdate() {
    if (SVGUplines.includes(agent.parentAgentId!)) {
      try {
        let last4Digits = payment.paymentType === "CC" ? payment.ccNumber?.slice(-4) : payment.achAccount?.slice(-4);
        let currentBillingDate = member.billingDate;
        const response = await axios.post(`${svgBillingDateCheck}`, {
          last4Digits: last4Digits,
          zip: member.zipCode,
          dtBilling: currentBillingDate,
        });
        const { success, message, billingDate: newBillingDate } = response.data;
        console.log('response from billing-date', success,message,newBillingDate)

        if (success === "true") {
          // Update Billing Date if successfully incremented (limit 5) or no new billing date needed.
          updateBillingandPaymentProcess(newBillingDate);
          return true
        }
        else {
          // Update Billing Date if max limit was reached.
          updateBillingandPaymentProcess(newBillingDate);
          // console.error('Error updating billing date:', message);
          return true
        }
      } catch (error) {
        // console.error('Failed to send billing date update request:', error);
        // setInvalidAnimation(true);
        return true
      }
    }
  }

  const navigate = useNavigate();

  const createMemberFailed = (response: ICreateMemberResponse) => {
    // TODO send to error tracking
    const msg = `Error creating member: ${response}`
    ErrorLog.getInstance().logError(new Error(msg), msg, LogLevel.warn);
    // setInvalidAnimation(true);

    // submitState controls purchase button loading indicator + enabled / disabled
    // setSubmitState({
    //   loading: false,
    // });

    // purchaseState controls back button visibility
    // setPurchaseState(false); 

    return false;
  }

  const createMemberSuccess = (agent: Agent, memberInstance: Member, navigate: NavigateFunction) => {
    setApplicationDate(new Date().getMonth());
    const state = { agentId: agent.id, memberId: memberInstance.id, memberFirstName: memberInstance.firstName, memberLastName: memberInstance.lastName }
    navigate(
      `/completed/${agent.id}`,
      { state }
    )

    return true;
  }

  const handlePurchaseButton = useCallback(async () => {
    if (!isBillingCurrent(member.billingDate)) {
      setInvalidBillingDate(true);
      return;
    }

    if (!agreement || !agreementTwo) {
      setDidNotAgreeToTheTerms(true);
      return;
    } else setDidNotAgreeToTheTerms(false);
    setSubmitState({
      //failed: false,
      //message: "",
      loading: true,
    });
    setPurchaseState(true);

    setView('Submitted'); // we will change to Completed once response returns

    await svgHandleBillingUpdate();

    memberInstance
      .createMember(
        selectedPlans,
        associatedPlan,
        agent.id!,
        zipCodeState,
        availableStatesForAgent,
        wantsDental,
        wantsVision
      )
      .then((response: ICreateMemberResponse) => {
        const { state } = response ?? {};

        return state
          ? createMemberSuccess(agent, memberInstance, navigate)
          : createMemberFailed(response);
      });
  }, [
    agent,
    agreement,
    agreementTwo,
    associatedPlan,
    availableStatesForAgent,
    dependents,
    member,
    payment,
    selectedPlans,
    setMemberInstance,
    zipCodeState,
  ]);

  const billingDate = formatDate(new Date(member.billingDate || ""));
  const today = formatDate(new Date());

  const [view, setView] = useState('AgreementForm');

  return (
    <div className="xl:pt-17 gap flex min-h-[70vh] w-full flex-col gap-1 px-2 py-2">
      {view === 'AgreementForm' &&
        <AgreementForm
          agentId={agent?.id}
          agreement={agreement}
          agreementTwo={agreementTwo}
          handlePurchaseButton={handlePurchaseButton}
          didNotAgreeToTheTerms={didNotAgreeToTheTerms}
          setAgreement={setAgreement}
          setAgreementTwo={setAgreementTwo}
          submitState={submitState}
        />
      }
      {view === 'Submitted' &&
        <Submitted agentId={agentId} memberId={undefined} memberFirstName={memberInstance.firstName} memberLastName={memberInstance.lastName} setMemberInstance={setMemberInstance} />
      }
      <ErrorModal visible={invalidAnimation} message="There was an error while completing the purchase, please check your data and try again later" />
      {invalidBillingDate && <BillingDateModal handleIncorrectBillingDate={handleIncorrectBillingDate} />}
    </div>
  );
};

export default Agreements;